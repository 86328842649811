<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
        :loading="loadingQuestions"
        :dataSource="questionTableData"
        :columns="questionTableColumns"
        :pagination="{
          defaultPageSize: 10,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
        @change="paginationChange"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import { TableStyleWrapper } from '../../user/style';
import { TableWrapper } from '../../styled';
import { computed, defineComponent } from 'vue';
import {useStore} from "vuex";
import moment from "moment";
import {useRoute, useRouter} from "vue-router";
import {useAbility} from "@casl/vue";

const questionTableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Naam',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Aangemaakt op',
    dataIndex: 'createTimestamp',
    key: 'createTimestamp',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const QuestionListTable = defineComponent({
  name: 'QuestionListTable',
  components: { TableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const questions = computed(() => state.question.questions);
    const loadingQuestions = computed(() => state.question.loadingQuestions);
    const currentPage = computed(() => state.question.currentPage);
    const count = computed(() => state.question.count);
    const router = useRouter();
    const {matched} = useRoute();
    const {path} = matched[1];
    const { can } = useAbility();
    const handleDelete = async (id) => {
      await dispatch('deleteQuestion', id);
      await dispatch('getQuestions', {page: state.question.currentPage, pageSize: state.question.pageSize});
    };
    const handleEdit = (id) => {
      router.push(`${path}/${id}`);
    };
    const questionTableData = computed(() =>
        questions.value.map((question) => {
        const { id, name, createTimestamp } = question;

        return {
          key: id,
          id: id,
          name: (
            <div class="user-info">
              <figcaption>
                <sdHeading class="user-name" as="h6">
                  {name}
                </sdHeading>
              </figcaption>
            </div>
          ),
          createTimestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
          action: (
              <div className="table-actions">
                {can('view', 'question') ? (
                    <sdButton onClick={() => handleEdit(id)} className="btn-icon" to="#" type="default" shape="circle">
                      <sdFeatherIcons type="edit" size="16"/>
                    </sdButton>
                ) : ('')}
                {can('delete', 'question') ? (
                    <a-popconfirm
                        title="Weet je het zeker? U kunt dit niet meer terugdraaien!"
                        ok-text="Ja"
                        cancel-text="Nee"
                        placement="leftTop"
                        onConfirm={() => handleDelete(id)}
                    >
                      <sdButton className="btn-icon" type="default" to="#" shape="circle">
                        <sdFeatherIcons type="trash-2" size="16"/>
                      </sdButton>
                    </a-popconfirm>
                ) : ('')}
              </div>
          ),
        };
      }),
    );

    const rowSelection = {
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled question', // Column configuration not to be checked
        name: record.name,
      }),
    };

    const paginationChange = async (event) => {
      await dispatch('setCurrentPageQuestions', event.current);
      await dispatch('getQuestions', {page: event.current, pageSize: state.question.pageSize});
    }

    return { questionTableColumns, questionTableData, rowSelection, loadingQuestions, paginationChange, currentPage, count };
  },
});

export default QuestionListTable;
</script>
