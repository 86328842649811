<template>
 <sdDrawer
  ref="QuestionAddRef"
  :form="form"
  btnText="Toevoegen"
  submitBtnText="Opslaan"
  title="Product vraag aanmaken"
  type="submit"
 >
  <FormValidationWrap>
   <a-form ref="formRef" :model="form" layout="vertical" @finish="CreateQuestion">
    <a-row :gutter="16">
     <a-col :span="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Naam" name="name">
       <a-input v-model:value="form.name" placeholder=""/>
      </a-form-item>
     </a-col>
     <a-col :span="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Weergavenaam" name="displayName">
       <a-input v-model:value="form.displayName" placeholder=""/>
      </a-form-item>
     </a-col>
     <a-col :span="12">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Type" name="inputType">
       <a-select v-model:value="form.inputType" :allowClear="true" class="sDash_fullwidth-select" size="large">
        <a-select-option v-for="(item,index) in QuestionInputTypeOptions" :key="index"
                         :value="item.value" name="inputType">
         {{ item.key }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
     <a-col v-if="form.inputType === 'TEXT' || form.inputType === 'INTEGER'" :span="12">
      <a-form-item label="Standaard waarde" name="inputDefaultValue">
       <a-input v-model:value="form.inputDefaultValue" placeholder=""/>
      </a-form-item>
     </a-col>
     <a-col v-else-if="form.inputType === 'SELECT' || form.inputType === 'INTEGER'" :xs="12">
      <a-form-item label="Standaard waarde" name="inputType">
       <a-select v-model:value="form.inputDefaultValue" :allowClear="true" class="sDash_fullwidth-select" size="large">
        <a-select-option v-for="(item,index) in form.inputValues" :key="item + index"
                         :value="form.inputValues[index]" name="inputType">
         {{ form.inputValues[index] }}
        </a-select-option>
       </a-select>
      </a-form-item>
     </a-col>
    </a-row>
    <a-row v-if="form.inputType === 'SELECT'" :gutter="30">
     <a-col :xs="24">
      <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" class="hidden-input"
                   label="Meerkeuze opties"
                   name="inputValues" style="margin-bottom:0px">
       <a-input v-model:value="form.inputValues" placeholder="" style="visibility: hidden;" type="hidden"/>
      </a-form-item>
     </a-col>
    </a-row>
    <a-row v-if="form.inputType === 'SELECT' && form.inputValues.length > 0" :gutter="30">
     <template v-for="(item, index) in form.inputValues" :key="index">
      <a-col :xs="16">
       <a-form-item :name="['inputValues', index]"
                    :rules="{required: true,message: 'Dit veld is verplicht'}">
        <a-input v-model:value="form.inputValues[index]" :name="['inputValues', index]"
                 type="text"/>
       </a-form-item>
      </a-col>
      <a-col :xs="8"
             style="vertical-align: middle;display: flex;justify-content: start;">
       <sdButton class="ml-4" size="small" type="danger"
                 @click.prevent="handleDeleteInputValue(index)">
        <sdFeatherIcons size="14" type="trash"/>
       </sdButton>
      </a-col>
     </template>
    </a-row>
    <a-row v-else-if="form.inputType === 'SELECT'">
     <a-col :xs="24">
      <a-empty :image="simpleImage">
       <template #description>
        <span>Geen meerkeuze opties aangemaakt, druk op plus om opties toe te voegen!</span>
       </template>
      </a-empty>
     </a-col>
    </a-row>
    <a-row v-if="form.inputType === 'SELECT'">
     <a-col :xs="24">
      <sdButton size="small" type="primary" @click.prevent="handleAddInputValue">
       <sdFeatherIcons size="14" type="plus"/>
       <span>Optie toevoegen</span>
      </sdButton>
     </a-col>
    </a-row>
    <div
     :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
    >
     <a-button :disabled="loadingCreateQuestion" :loading="loadingCreateQuestion" class="btn-signin" html-type="submit"
               size="large"
               type="primary"> Aanmaken
     </a-button>
    </div>
   </a-form>
  </FormValidationWrap>
 </sdDrawer>
</template>
<script>
import {computed, defineComponent, reactive, ref} from 'vue';
import {FormValidationWrap} from "../styled";
import {useStore} from "vuex";
import {QuestionInputTypeOptions} from "../../utility/enums";
import {Empty} from "ant-design-vue";

const QuestionAdd = defineComponent({
 name: 'QuestionAdd',
 components: {
  FormValidationWrap,
 },
 data() {
  return {
   simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
  };
 },
 setup() {
  const QuestionAddRef = ref()
  const formRef = ref();
  const {state, dispatch} = useStore();
  const loadingCreateQuestion = computed(() => state.question.loadingCreateQuestion);
  const form = reactive({
   name: null,
   displayName: null,
   inputDefaultValue: null,
   inputValues: [],
   inputType: 'TEXT',
   type: 'OTHER',
  });
  const handleAddInputValue = () => {
   form.inputValues.push('');
  }
  const handleDeleteInputValue = (index) => {
   form.inputValues.splice(index, 1);
  }
  const closeDrawer = () => {
   QuestionAddRef.value.onClose();
   formRef.value.resetFields();
   dispatch('getQuestions');
  }
  const CreateQuestion = () => {
   dispatch('createQuestion', {value: form, close: closeDrawer});
  };
  return {
   form,
   CreateQuestion,
   loadingCreateQuestion,
   QuestionAddRef,
   formRef,
   QuestionInputTypeOptions,
   handleAddInputValue,
   handleDeleteInputValue,
  };
 },
});

export default QuestionAdd;
</script>
<style>
.hidden-input .ant-form-item-control-input {
 min-height: 0px !important;
}
</style>
