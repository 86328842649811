<template>
  <CardToolbox>
    <sdPageHeader title="Product vragen">
      <template #subTitle>
        <span class="title-counter">{{ count }} question{{ count > 0 && count < 2 ? '' : 's' }}</span>
        <AutoCompleteStyled style="width:100%" placeholder="Zoeken op naam...">
          <a-input type="input" v-model:value="search">
            <template #suffix>
              <sdFeatherIcons type="search"/>
            </template>
          </a-input>
        </AutoCompleteStyled>
      </template>
      <template #buttons>
        <QuestionAdd v-if="$can('create', 'question')"/>
      </template>
    </sdPageHeader>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col :md="24">
        <QuestionTable/>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import QuestionTable from './component/QuestionTable';
import {computed, ref, defineComponent, onMounted, watch} from 'vue';
import {useStore} from 'vuex';
import {Main, CardToolbox, AutoCompleteStyled} from '../styled';
import QuestionAdd from "./QuestionAdd";

const QuestionList = defineComponent({
  name: 'QuestionList',
  components: {QuestionAdd, Main, CardToolbox, QuestionTable, AutoCompleteStyled},
  data() {
    return {
      showEditDrawer: false,
    }
  },
  setup() {
    const {state, dispatch} = useStore();
    const searchData = computed(() => state.headerSearchData);
    const count = computed(() => state.question.count);
    const selectedRowKeys = ref(0);
    const selectedRows = ref(0);
    const search = ref(null);
    let debounce = null;

    watch(search, (query) => {
      clearTimeout(debounce)
      debounce = setTimeout(async () => {
        const searchValues = {
          q: query,
          pageSize: state.question.pageSize,
          page: 1,
        };
        await dispatch('setCurrentPageQuestions', 1);
        await dispatch('getQuestions', searchValues);
      }, 600);
    });

    onMounted(() => {
      dispatch('getQuestions', {page: state.question.currentPage, pageSize: state.question.pageSize});
    });

    return {
      searchData,
      selectedRowKeys,
      selectedRows,
      count,
      search,
    };
  },
});

export default QuestionList;
</script>
